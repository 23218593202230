
.section-bgimg-professional {
  height: 400px;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../_images/professional/steadytemp_app_mit_temperaturkurve_steadysense.png');

  @media only screen and (max-width: $tablet){
    background-position: 60%;
    background-image: url('../_images/professional/steadytemp_app_mit_temperaturkurve_steadysense_mobile.png')!important;
  }
  h1 {
    text-size: 3rem;
    @media onyl screen and (max-width:$tablet) {
       text-size:1.5rem;
    }
  }
}

.steadytemp-video{
  width: 100%;
  height: 300px;

  @media only screen and (max-width: $tablet){
    height: 200px;
  }
}

.arrow-line{
  width: 200%;
}

.cobas-pulse {
  @media only screen and (max-width: $tablet) {
    padding-top: 13.5rem;
  }
}

ul.benefits {
  list-style-type: disc;
  padding-left: 1rem;
}


.usp1 {
  background-image: url(../_images/professional/pics/arrow.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;

  h3 {
    font-size: 1rem;
  }

  p {
    width: 90%;
    padding:0;
    margin-bottom:0;
  }
}

.img90 {
  width:90%;
}

.blog_img_teaser {
  width:70%;
}

.blog-fix {
  width:80%;
}

.slider-item {
  margin-bottom: 2rem;
}
