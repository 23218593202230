.section-bgimage-home {
  background-image: url('../_images/home/header-background-blue.jpg');
  background-position: bottom;
  @media only screen and (max-width: $tablet) {
    background-position: left;
  }

  @media only screen and (min-width: $tablet) and (max-width: $desktop) {
    background-position: top;
  }
}

.home {
  height: calc(100vh - 52px) !important;
  @media only screen and (max-width: 800px) {
    height: auto !important;
  }
}

.logo-white {
  @media only screen and (max-width: $desktop) {
    width: 60vw;
  }
}

.step-img {
  img {
    height: 9rem;
  }
}

.i-am-sick-img {
  width: 80vw;
  max-width: $desktop;
  @media only screen and (max-width: $tablet) {
    width: 100vw;
    max-width: 100vw;
  }
}

.m-auto {
  margin: auto;
}

.is-reverse {
  flex-direction: row-reverse;
}

.component-text {
  h2 {
    padding-top: 10px;
    padding-bottom: 20px;
    @media only screen and (max-width: 800px) {
      padding-bottom: 20px;
    }
  }

  h2::after {
    content: '';
    width: 25%;
    position: absolute;
    height: 1px;
    background: #008fdf;
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, #99f188), color-stop(50%, #008fdf), color-stop(100%, #99f188));
    background: linear-gradient(90deg, $gradient-left-color3, $gradient-right-color3);
    @media only screen and (max-width: $tablet) {
      width: 50%;
    }
  }

  h2.line-left::after {
    @media only screen and (min-width: $tablet) {
      left: 24%;
    }
  }
}

.img-packaging-contact {
  max-height: 38vw;
  width: auto;
  @media only screen and (max-width: $tablet) {
    max-height: 60vw;
  }
}

.shop-col {
  margin: auto;
  @media only screen and (max-width: 500px) {
    padding: 0 2rem 0 2rem;
  }
}

.no-padding {
  @media only screen and (min-width: 500px) {
    padding: 0 !important;
  }
}

.bipa-img-small {
  max-width: 30vw;
  padding-top: 0.5rem;

  @media only screen and (min-width: 300px) {
    max-width: 30%;
  }
  @media only screen and (min-width: $tablet) {
    max-width: 20%;
  }
}

.things-img-small {
  max-width: 30vw;
  @media only screen and (min-width: 300px) {
    max-width: 38%;
    padding-top: 0.7rem;
  }
  @media only screen and (min-width: $tablet) {
    max-width: 34%;
    padding-top: 0.7rem;
  }
}

.has-border-width-medium {
  background-color: transparent !important;
  border-width: medium;
}


.things-width-correction {
  @media only screen and (min-width: $tablet) {
    margin-top: 1rem;
  }
}

.red-cross {
  @media only screen and (max-width: $tablet) {
    margin-top: -2rem;
  }
}

.available-size {
  @media only screen and (max-width: $tablet) {
    width: 50%;
  }
}

ul.steadytemp-info-list {
  list-style-type: disc;
  padding-left: 2rem;

}

.img-start {
  width: 40vw;
  max-width: 400px;
  @media only screen and (max-width: 500px) {
    width: 86vw;
    margin-left: 5vw;
  }
}
// title pictures
.title_home {
  // text-align: right;
  width: 45%;
  // margin: auto;
  margin-left: 50px;
  padding-bottom:2rem;
  h1 {
    font-size: 5vw!important;
  }
  @media only screen and (min-width: $widescreen) {
    //width:668px;
    //margin-left:473px;
    h1 {
      font-size: 48px!important;
    }
  }
  @media only screen and (max-width: 500px) {
    // padding:0 1rem 1rem 0.5rem;
    margin:auto;
    text-align:center;
    width:80%;
    h1 {
      font-size:6.5vw!important;
      font-weight:400;
    }
  }
}

.title-text {
  position:relative;
  padding-top: calc(20vw * 279 / 375);
  @media only screen and (min-width: $widescreen) {
    padding-top: 181px;
  }
  @media only screen and (max-width: 500px) {
    padding-top: 6vw;
  }
}

.home-start {
  display: flex;
  height: calc(100vw * (667 / 1000));
  padding:0!important;
  max-height: 811px!important;
  @media only screen and (max-width: 500px) {
    height: calc(100vw * 1750 / 1000);
    //height: 50vw;
    //height: 90vw;
    // max-height: 885px!important;
  }
}

.img-title {
  height:auto;
  width: 100vw;
  position: absolute;
  max-width: $widescreen;
  // @media only screen and (max-width: 500px) {
  //   height:calc(100vh - 50px);
  //   width:auto;
  // }
}

.title-mobile{
  @media only screen and (max-width: 500px) {
    display: none!important;
  }
}

.title-tablet{
  @media only screen and (min-width: 501px) {
    display: none!important;
  }
}

.icons-padding {
  padding-top:0!important;
  margin-top:-30px;
}

.video{
  max-width:784px;
  width:100%;
}

.img-mobile {
  @media only screen and (max-width: 500px) {
    width:100vw;
  }
}

.h175 {
  height:175px;
}

.h60 {
  height:60px;
}

.h70 {
  height:70px;
  maring-bottom:0;
}
